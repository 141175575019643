export default [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Home',
        route: 'dashboard-ecommerce',
        icon: 'ShoppingCartIcon',
      },
      /*{
        title: 'Analytics',
        route: 'dashboard-analytics',
        icon: 'ActivityIcon',
      },*/
    ],
  },
]
